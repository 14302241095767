<template>
    <div id="users">
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <!-- 表格区域 -->
            <el-table v-loading="loading" size="small" :data="softwareForApplyList" border style="width: 100%;"
                      height="600" @cell-click="trialCountList">
                <el-table-column label="No" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + (queryMap.currentPage - 1) * queryMap.pageSize + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="softwareName" width="150" show-overflow-tooltip label="软件服务名"></el-table-column>
                <el-table-column prop="status" width="90" show-overflow-tooltip label="是否可试用">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status==0">是</el-tag>
                        <el-tag type="warning" v-if="scope.row.status==1">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="发布日" width="150" prop="publishTime">
                </el-table-column>
                <el-table-column prop="description" width="100" show-overflow-tooltip label="软件说明">
                    <template slot-scope="scope">
                        <el-tag type="info">浏览说明</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="applyCenterCount" label="申请中数量" width="150"></el-table-column>
                <el-table-column prop="impowerCount" label="已授权数量" width="150"></el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button size="small" type="success" plain
                                   @click="edit(scope.row)">申请
                        </el-button>
                        <el-button size="small" type="warning" plain
                                   @click="resume(scope.row)">履历
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-drawer
                :title="this.title"
                :visible.sync="drawer"
                size="35%">
            <div style="margin: auto; margin-top: 13%; margin-left: 10%">
                <el-card class="box-card" style="width:90%;">
                    <div v-html="this.addForm.description"></div>
                </el-card>
            </div>
        </el-drawer>

        <el-dialog :title="dialogTitle" class="class_dialog_hospital" @close="hisCloseDialog"
                   :visible.sync="addDialogVisible" width="1400px">
            <el-card class="box-card">
                <!-- 表单 -->
                <el-table v-loading="loading" size="small" :data="ApplyList" border style="width: 1306px;">
                    <el-table-column label="No" width="50">
                        <template slot-scope="scope">
                            {{ scope.$index + (queryMap.currentPage - 1) * queryMap.pageSize + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="applyDate" width="150" show-overflow-tooltip label="申请日时"></el-table-column>
                    <el-table-column prop="status" width="100" show-overflow-tooltip label="分类">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.type==0">可试用</el-tag>
                            <el-tag type="info" v-if="scope.row.type==1">正式</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endTime" width="150" show-overflow-tooltip label="使用期限日"></el-table-column>
                    <el-table-column prop="applyCount" width="100" show-overflow-tooltip label="申请数量"></el-table-column>
                    <el-table-column prop="applyExplain" width="250" show-overflow-tooltip
                                     label="申请说明"></el-table-column>
                    <el-table-column prop="zhuangtai" width="100" show-overflow-tooltip label="状态">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.status==0">申请中</el-tag>
                            <el-tag type="info" v-if="scope.row.status==1">一次审批</el-tag>
                            <el-tag type="success" v-if="scope.row.status==2">二次审批</el-tag>
                            <el-tag type="info" v-if="scope.row.status==3">Ukey制成</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" show-overflow-tooltip label="审批日时">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type==0">
                                {{ scope.row.approve1Date }}
                            </span>
                            <span v-if="scope.row.type==1">
                                {{ scope.row.approve2Date }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column width="250" show-overflow-tooltip label="审批说明">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type==0">
                             {{ scope.row.approve1Comment }}
                            </span>
                            <span v-if="scope.row.type==1">
                                {{ scope.row.approve2Comment }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-dialog>

        <el-dialog :title="dialogTitle" class="class_dialog_hospital" @close="closeDialog"
                   :visible.sync="DialogVisible">
            <el-card class="box-card">
                <el-form
                        :model="addForm"
                        :label-position="labelPosition"
                        :rules="addFormRules"
                        ref="addFormRef"
                        label-width="90px">
                    <el-form-item label="类别:" prop="type" label-width="90px">
                        <el-radio-group v-model="addForm.type" @change="editLabel">
                            <el-radio v-if="addForm.status===0" :label="0">试用</el-radio>
                            <el-radio :label="1">正式</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label-width="90px">
                        <el-table v-loading="loading" size="small" ref="multipleTable" :data="tableData" border style="width: 555px; "
                                  @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="ukeyId" width="150" show-overflow-tooltip
                                             label="Ukey"></el-table-column>
                            <el-table-column prop="agentId" width="150" show-overflow-tooltip
                                             label="Agent端末"></el-table-column>
                            <el-table-column prop="grant" width="100" show-overflow-tooltip label="赋权状态">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="scope.row.grant==true">已赋权</el-tag>
                                    <el-tag type="info" v-if="scope.row.grant==false">未赋权</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="dueDate" width="100" show-overflow-tooltip
                                             label="使用期限"></el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item label="申请说明:" prop="applyExplain" label-width="90px">
                        <el-input type="textarea" v-model="addForm.applyExplain" maxlength="200" :rows="5"></el-input>
                    </el-form-item>
                </el-form>
            </el-card>
            <span slot="footer" class="dialog-footer">
                   <el-button size="small" type="primary"
                              icon="el-icon-check" plain
                              @click="addApply()">申请
                   </el-button>
                   <el-button size="small" type="warning"
                              icon="el-icon-close" plain
                              @click="closeDialog">取消
                   </el-button>
             </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                type: '',
                name: '',
                dialogTitle: '',
                labelPosition: "right",
                tableData: [],
                ApplyList: [],
                addFormRules: {
                    applyExplain: [
                        {required: true, message: '请输入申请说明', trigger: 'blur'}],
                    type: [
                        {required: true, message: '请选择类别', trigger: 'blur'}
                    ],
                },
                addForm: {},
                addDialogVisible: false,
                btnDisabled: false,
                loading: true,
                total: 0,
                DialogVisible: false,
                softwareForApplyList: [],
                ukeyList: [],
                //查询对象
                queryMap: {
                    startTime: "",
                    endTime: "",
                    currentPage: 1,
                    pageSize: 10,
                },
                title: '',
                drawer: false
            };
        },

        methods: {

            /**
             * 统计个数列表
             **/
            async trialCountList(row, column, cell, event) {
                if (column.label === "软件说明") {
                    this.title = "软件说明"
                    this.addForm.description = row.description
                    this.drawer = true
                }
            },

            /**
             * 遍历
             **/
            handleSelectionChange(selection) {
                this.ukeyList = [];
                selection.forEach(item => {
                    this.ukeyList.push(item.ukeyId);
                });
            },

            /**
             * 申请
             **/
            async edit(row) {
                this.DialogVisible = true;
                this.dialogTitle = "申请";
                this.addForm=row
                this.addForm.type = 1;
                const {data: res} = await this.$http.get("/factorymng/ukey/canapply/list?softId="+ row.softId)
                this.tableData = res.data;

            },

            /**
             * vue 强制刷新
             **/
            editLabel() {
                this.$forceUpdate();
            },

            /**
             * 添加申请
             */
            async addApply() {
              this.$refs.addFormRef.validate(async valid => {
                if (!valid) {
                  return;
                } else {
                  if (this.ukeyList.length == 0) {
                    return this.$notify.error({
                      title: '操作失败',
                      message: '请选择ukey'
                    });
                  } else {
                    this.addForm.ukeyId = this.ukeyList.toString();
                    const {data: res} = await this.$http.post("/factorymng/apply", this.addForm);
                    if (res.success) {
                      this.$notify.success({
                        title: '操作成功',
                        message: '添加成功',
                      });
                      this.addForm = {};
                      this.ukeyList=[];
                      this.getSoftwareForApply();
                    } else {
                      return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                    }
                  }}
                this.DialogVisible=false;
              });
            },

            /**
             * 加载列表
             */
            async getSoftwareForApply() {
                const {data: res} = await this.$http.get("/factorymng/apply", {
                    params: this.queryMap
                });
                if (!res.success) {
                    return this.$message.error("获取申请列表失败:" + res.data.errorMsg);
                }
                this.total = res.data.total;
                this.softwareForApplyList = res.data.list;
            },

            /**
             * 申请履历
             **/
            async resume(row) {
                this.addForm.softwareName = row.softwareName;
                this.addDialogVisible = true;
                this.dialogTitle = this.addForm.softwareName + "申请履历";
                let factoryId = localStorage.getItem("factoryId")
                const {data: res} = await this.$http.get("/factorymng/apply/history/" + row.softId );
                if (!res.success) {
                    return this.$message.error("获取申请履历列表失败:" + res.data.errorMsg);
                }
                this.ApplyList = res.data;
            },

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10,
                };
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.DialogVisible = false;
                this.addForm = {};
                this.ukeyList=[]
                this.$refs.multipleTable.clearSelection();
            },

            /**
             * 关闭添加弹出框
             */
            hisCloseDialog() {
                this.DialogVisible = false;
                this.addForm = {};
                this.ukeyList=[]
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
            },

            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
            },

        },
        created() {
            this.getSoftwareForApply();
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    };
</script>

<style scoped>
    .el-table__body .el-table__row.hover-bg td {
        background-color: #F5F7FA;
    }
</style>


